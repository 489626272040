// *******~ Import ~******** //
// React
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Assets
// Components
import Main from "./main";
import Sitemap from "../src/pages/seo/sitemap";
// import {ThemeSetter} from "./common/theme/components/themeshetter";
import ThemeProvider from "./common/theme/components/contexts/themeprovider";
import {
  // PaymentRequestButtonElement,
  useStripe,
  // useElements,
} from "@stripe/react-stripe-js";

import { connect } from "react-redux";
import { setStripe } from "./actions/stripe/setStripeActions";

// CSS
// Images
// Icons
// *******~ Import ~******** //
export const OrderFlowProvider = createContext({});
export const OrderFlow = () => useContext(OrderFlowProvider);

const App = ({ setStripe }) => {
  const stripe = useStripe();
  const mobileref = useRef();
  // const location = useLocation(); 
  // const elements = useElements();
  useEffect(() => {
    if (stripe) {
      setStripe(stripe); // Dispatch the Stripe object to Redux
    }
  }, [stripe, setStripe]);

  const [loginPopupShow, setLoginPopupShow] = useState(false);
  const [appLoginPopupShow, setAppLoginPopupShow] = useState(false);
  const [currentPage, setCurrentPage] = useState("signIn"); // "signIn" or "otp"
  const [getOrderTime, setOrderTime] = useState(
    localStorage.getItem("orderTime")
  );
  const [getOrderMode, setOrderMode] = useState(
    localStorage.getItem("orderMode")
      ? localStorage.getItem("orderMode")
      : "Delivery"
  );
  const [getCollectionTab, setCollectionTab] = useState(true);
  const [getDeliveryTab, setDeliveryTab] = useState(true);
  const [moveToCheck, setMoveToCheck] = useState(
    localStorage.getItem("moveToCheck")
      ? localStorage.getItem("moveToCheck")
      : false
  );
   //menufilterShow mobile
   const [menuFilterShow,setMenuFilterShow]=useState(false);
  const [stuartStatus, setStuartStatus] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [footerLoading, setFooterLoading] = useState(false);
  //postcode change
  const [reslimit, setReslimit] = useState(1);
  const [restaurantData, setRestaurantData] = useState([]);
  const [hasFetchedData, updateHasFetchedData] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(null);
  const [lastOrder, setLastOrder] = useState(false);
  useEffect(() => {
    setFooterLoading(false);
    setCartQuantity(localStorage.getItem("cartQty"));
  }, []);
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        //  setIpAddress(data.ip);
        localStorage.setItem("ip", data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  }, []);
  useEffect(() => {
    // console.log(footerLoading);
  }, [footerLoading]);
  const handleclick = () => {
    setFooterLoading(false);
  };
  const TopQty = () => {
    const cartQty = localStorage.getItem("cartQty");
    setCartQuantity(cartQty);
  };
  const menuFooter = !window.location.pathname.includes("/menu") && !window.location.pathname.includes("/checkout") && !window.location.pathname.includes("/myaccount");
  return (
    <>
      <ThemeProvider>
        <OrderFlowProvider.Provider
          value={{
            loginPopupShow,
            setLoginPopupShow,
            getOrderTime,
            setOrderTime,
            getOrderMode,
            setOrderMode,
            getCollectionTab,
            setCollectionTab,
            getDeliveryTab,
            setDeliveryTab,
            moveToCheck,
            setMoveToCheck,
            stuartStatus,
            setStuartStatus,
            restaurant,
            setRestaurant,
            footerLoading,
            setFooterLoading,
            handleclick,
            reslimit,
            setReslimit,
            restaurantData,
            setRestaurantData,
            hasFetchedData,
            updateHasFetchedData,
            mobileref,
            cartQuantity,
            setCartQuantity,
            TopQty,
            lastOrder,
            setLastOrder,
            menuFooter,
            appLoginPopupShow, setAppLoginPopupShow,
            currentPage, setCurrentPage,
            menuFilterShow,setMenuFilterShow,
          }}
        >
          <Router basename={"/"}>
            <Routes>
              <Route path="/sitemap.xml" element={<Sitemap />} />
              <Route path="/*" element={<Main/>} />
            </Routes>
          </Router>
        </OrderFlowProvider.Provider>
      </ThemeProvider>
    </>
  );
};

const mapDispatchToProps = {
  setStripe,
};

export default connect(null, mapDispatchToProps)(App);
