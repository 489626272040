// src/Addressmyaccount.js
import React, { useState, useContext, useEffect } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import "./settingsfk.scss";
import { useNavigate, useLocation } from 'react-router-dom';
import {
  MdOutlineArrowBackIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import "../addressbook/css/addressmodal.scss";

const Settingsfusion = () => {
  const breakpoint = 767;
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const { theme } = useContext(ThemeContext);
  const [current, setCurrent] = useState(null);
  const [show, setShow] = useState(false);

  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/BlogImages/My-accoount-arrow.png';
  const navigate = useNavigate();

  const handlemyaccountclick = () => {
    navigate('/myaccount');
  };
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (v) => {
    setCurrent(v);
    setShow(true);
  };
  const navigateto = useNavigate();
  if (!location.pathname?.includes("/myaccount") && width > breakpoint) {
    navigateto("/myaccount/settingsfk");
  }

  const reasons = [
    "I Don't Want To Use Fusion Kitchen Anymore",
    "I'm Using A Different Account",
    "I'm Worried About My Privacy",
    "You're Sending Too Many Emails/Notifications",
    "The App Is Not Working Properly",
    "Other"
  ];

  const Deleteaccount = () => (
    <div>
      {/* <MdOutlineArrowBackIos className='arrowtop-icon' onClick={handlemyaccountclick} /> */}
      <img
        src={theme === "dark" ? Imagetopdark : Imagetop}
        onClick={handlemyaccountclick}
        className='backarrow-setting'
      />

      <div className="card-item" onClick={() => navigate('/profileorder')}>
        <span className="title1">Account Settings</span>
      </div>
      <div className="card-item" onClick={() => handleShow("Deletecontent")}>
        <span className="title1">Delete Account</span>
      </div>
    </div>
  );

  const Deletecontent = () => (
    <div >
      <img
        src={theme === "dark" ? Imagetopdark : Imagetop}
        onClick={handlemyaccountclick}
        className='backarrow-setting'
      />
      <h2 className="delectcontentTag" onClick={() => handleShow("Deleteconform")} >Delete Account</h2>
      {reasons.map((reason, index) => (
        <p key={index} className='delectcontent-ptag'>
          {reason} <img src="https://fusionbucket.co.uk/BlogImages/Arrow-vector.png" alt="arrow-icon" className='arrow-settings' />
        </p>
      ))}
    </div>
  );

  const DeleteConform = () => (
    <>
      <div>
        <img
          src={theme === "dark" ? Imagetopdark : Imagetop}
          onClick={handlemyaccountclick}
          className='backarrow-setting'
        />
        <h3>You Have Requested The Deletion Of Your Account</h3>
        <p className='delete-contentptag'>
          Please Note That The Account Will Get Deleted By Proceeding With This Request You Understand And Acknowledge That Information Associated With Your Account Cannot Be Retrieved By You Once It Has Been Deleted.
        </p>
      </div>
      <Button fullwidth className='delete-button'>
        Delete My Account Now
      </Button>
      <Button fullwidth className='backto-button'
        onClick={handlemyaccountclick}>
        Back To The Settings
      </Button>
    </>
  );

  return (
    <section>

      {width > breakpoint ? (
        <>
          <div className='address-module'>
            <Container>
              <div>  
                <h5 className='arrow-text'> <img
                  src={theme === "dark" ? Imagetopdark : Imagetop}
                  onClick={handlemyaccountclick}
                  className='backarrow-setting'
                />Settings</h5>
              </div>
              <div className="card-item" onClick={() => handleShow("AccountSetting")}>
                <span className="title1">Account Settings</span>
              </div>
              <div className="card-item" onClick={() => handleShow("Deletecontent")}>
                <span className="title1">Delete Account</span>
              </div>
            </Container>
          </div>
          {show &&
            <Modal show={show} onHide={handleClose}
              // dialogClassName="bottom-modal"
              id="addr-book-modalsetting"
              dialogClassName='bottom-modalsettingdesktop'
            >
              <Modal.Body
                className={theme === "dark" ? "settings-modalwebdark" : "settings-modalweb"}
              >
                {current === "AccountSetting" && <Deleteaccount />}
                {current === "Deletecontent" && <Deletecontent />}
                {current === "Deleteconform" && <DeleteConform />}
              </Modal.Body>
            </Modal>
          }
        </>
      ) : (
        <>
          <div className='address-module'>
            <Container>
              <div>
                
                <h5 className='heading-tag'><MdOutlineArrowBackIos className='arrowtop-iconaddress' onClick={handlemyaccountclick} />Settings</h5>
              </div>
              <div className="card-item" onClick={() => handleShow("AccountSetting")}>
                <span className="title1">Account Settings</span>
              </div>
              <div className="card-item" onClick={() => handleShow("Deletecontent")}>
                <span className="title1">Delete Account</span>
              </div>
            </Container>
          </div>
          {show &&
            <Modal show={show} onHide={handleClose}
              dialogClassName="bottom-modal"
            >
              <Modal.Body className={theme === "dark" ? "settings-modaldark" : "settings-modal"}>
                {current === "AccountSetting" && <Deleteaccount />}
                {current === "Deletecontent" && <Deletecontent />}
                {current === "Deleteconform" && <DeleteConform />}
              </Modal.Body>
            </Modal>
          }
        </>
      )}

    </section>
  );
};

export default Settingsfusion;
