import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SuccessLottie from "./img/payment-success.json";
import FaildLottie from "./img/payment-faild.json";
import FailedMinOrder from "./img/Animation1.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { MdOutlineCancel } from "react-icons/md";
import { FaDotCircle } from "react-icons/fa";
import ThemeContext from "../../common/theme/components/contexts/themecontexts";
import { CheckoutContext } from "./checkoutV2";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

export function PaymentSuccess() {
  const { theme } = useContext(ThemeContext);
  const { paymentSuccessShow, setPaymentSuccessShow, paymentSuccessMsg, setPaymentSuccessMsg } = useContext(CheckoutContext);
  //   const [Successshow, setSuccessShow] = useState(false);

  const handleClose = () => setPaymentSuccessShow(false);
  //   const handleShow = () => setSuccessShow(true);

  return (
    <>
      <Modal
        show={paymentSuccessShow}
        centered
        className={`payment-success ${theme === "dark" ? "dark-popup" : null}`}
      >
        <Modal.Body>
          {/* <span className="close-btn" onClick={handleClose}>
            <MdOutlineCancel />
          </span> */}
          <div className="content">
            <div className="lottie-ani">
              {paymentSuccessMsg === "success" ? (<>
                <Player autoplay loop src={SuccessLottie}></Player>
              </>) : (
                <>
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="success" />

                  </div>
                </>
              )
              }
            </div>
            {paymentSuccessMsg === "success" ? (<>
              <h3>Success</h3>
              <p>
                we are delighted to inform you <br /> that we received your
                payment
              </p>
            </>) : (<>
              <p>Please wait while your payment is being verified.you will be redirected automatically</p></>)
            }
          </div>
          <div className="footer-btn-group">
            {/* <Button className="track-btn" onClick={handleClose}>
              Track Order
            </Button> */}
          </div>
          {paymentSuccessMsg === "success" ? (<>
            <div class="pulse">
              <FaDotCircle />
            </div></>) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
export function PaymentFaild({ }) {
  const { theme } = useContext(ThemeContext);
  const { faildshow, setFaildShow, cardErrorMessage } = useContext(CheckoutContext);
  //   const [Faildshow, setFaildShow] = useState(true);

  const handleClose = () => setFaildShow(false);
  //   const handleShow = () => setFaildShow(true);

  return (
    <>
      <Modal
        show={faildshow}
        onHide={handleClose}
        centered
        className={`payment-faild ${theme === "dark" ? "dark-popup" : null}`}
      >
        <Modal.Body>
          <span className="close-btn" onClick={handleClose}>
            <MdOutlineCancel />
          </span>
          <div className="content">
            <div className="lottie-ani">
              <Player autoplay loop src={FaildLottie}></Player>
            </div>
            <h3>Payment Failed</h3>
            <p>
              {cardErrorMessage ? cardErrorMessage : <>Unfortunately we have an issue with <br /> your payment, try again
                later</>}
            </p>
          </div>
          <div className="footer-btn-group">
            <Button className="change-pay-btn" onClick={handleClose}>
              Change Payment
            </Button>
            <Button className="track-btn" onClick={handleClose}>
              Try Again
            </Button>
          </div>
          <div class="pulse faild">
            <FaDotCircle />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export function MinOrderPopup({ HandleClick }) {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { setMinOrderModal,
    minOrderModal, minOrderMessage } = useContext(CheckoutContext);
  //   const [Faildshow, setFaildShow] = useState(true);

  
  const handleClose = () => setMinOrderModal(false);
  const handleGoBacKMenu = () => {
    const ClientPath = localStorage.getItem("clientPath") || "";
    navigate(`/${ClientPath}/menu`);
  }
  //   const handleShow = () => setFaildShow(true);

  return (
    <>
      <Modal
        show={minOrderModal}
        onHide={handleClose}
        centered
        className={`payment-faild ${theme === "dark" ? "dark-popup" : null}`}
      >
        <Modal.Body>
          <span className="close-btn" onClick={handleClose}>
            <MdOutlineCancel />
          </span>
          <div className="content">
            <div className="lottie-ani">
              <Player autoplay loop src={FailedMinOrder}></Player>
            </div>
            <h3>
              {minOrderMessage}
            </h3>
          </div>
          <div className="footer-btn-group">
            <Button className="change-pay-btn" onClick={handleGoBacKMenu}>
              Add More
            </Button>
            <Button className="track-btn" onClick={() => HandleClick("Collection")} style={{ color: '#0a1929' }}>
              Switch to Pickup
            </Button>
            {/* <div class="pulse1 faild"> */}
            {/* <FaDotCircle /> */}
            {/* </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export function PaymentComponent({ userData }) {
  const { autoshowPopup, setAutoShowPopup, localCustomerId, subTotal } = useContext(CheckoutContext);
  const { theme } = useContext(ThemeContext);
  const [autoOrderStatus, setautoOrderStatus] = useState("");
  let login_type = 5;
  if (userData) {
    if (userData.login_type || userData.login_type === 0) {
      login_type = userData.login_type;
    }
  }
  const path = localStorage.getItem("clientPath");

  const cart = localStorage.getItem("cart") && localStorage.getItem("cart")!== "undefined" ? JSON.parse(localStorage.getItem("cart")) : [];
  const orderTime = localStorage.getItem("orderTime") || "";
  let dateParts = "";
  let timePart = "";
  let formattedDate = "";
  if (orderTime) {
    let inputDate = orderTime;
    if (inputDate.includes("/")) {
      dateParts = inputDate.split(" ")[0].split("/"); // Split the date into parts
      timePart = inputDate.split(" ")[1]; // Get the time part
      // Create a Date object with the parts
      const dateObject = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timePart}`
      );
      // Format the date in the desired format
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const day = String(dateObject.getDate()).padStart(2, "0");
      const hour = String(dateObject.getHours()).padStart(2, "0");
      const minute = String(dateObject.getMinutes()).padStart(2, "0");
      formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
    } else {
      formattedDate = orderTime;
    }
  }

  useEffect(() => {
    if (autoOrderStatus !== "") {
      const data = {
        itemsDetail: cart,
        clientPath: path,
        totalPrice: subTotal,
        customerId: localCustomerId,
        logintype: login_type,
        orderTime: formattedDate,
        incompletedOrder: autoOrderStatus
      };
      const fetchConfiguration = async () => {
        try {
          // Fetch the data
          const response = await fetch("https://api-new.fusionkitchen.co.uk/inCompleteOrder", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Use application/json if sending JSON
            },
            body: JSON.stringify(data), // Convert object to JSON string
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.statusText}`);
          }
          const config = await response.json();
          if (config && config.status === true) {
            console.log(config);
            localStorage.removeItem("paymentStatus");
            setAutoShowPopup(false);
          }
        } catch (error) {
          console.error("Failed to load app configuration, error:", error);
        }
      };
      fetchConfiguration();
    }
  }, [autoOrderStatus]); // Dependency array

  const closePopup = () => {
    setAutoShowPopup(false);
    // localStorage.removeItem("paymentStatus"); // Clear status after showing popup
  };

  const handleCancelOrder = () => {
    setautoOrderStatus(1);
  }
  const handleConfirmOrder = () => {
    setautoOrderStatus(0);
  }

  return (
    <Modal
      show={autoshowPopup}
      centered
      className={`payment-faild ${theme === "dark" ? "dark-popup" : null} autorefresh`}
    >
      <Modal.Body style={{borderRadius:'15px !important'}}>
        {/* <span className="close-btn" onClick={closePopup}>
          <MdOutlineCancel />
        </span> */}
        <div className="content" >
          <div className="lottie-ani">
            {/* <Player autoplay loop src={FailedMinOrder}></Player> */}
          </div>
          <h3 style={{fontFamily: 'SegoeUI'}}>
            Payment in progress!
          </h3>
          <h6 style={{
            textAlign: 'center',
            fontFamily: 'Geist-Regular',
            letterSpacing: '0.4px',
            marginTop: '23px'
          }}>If leave, the transaction may cancel refunds will be processed in 1-2 days. </h6>
        </div>
        <div className="footer-btn-group">
          <Button className="change-pay-yes-btn" onClick={handleCancelOrder}>
            Yes
          </Button>
          <Button className="change-pay-no-btn" onClick={handleConfirmOrder}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal >
  );
};
