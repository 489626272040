import React, { useState } from 'react';
import { IoMdCart } from "react-icons/io";
import "./assets/showcart.scss";
import { IoMdClose } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import { FaShoppingCart } from "react-icons/fa";
import { Navigate, useNavigate } from 'react-router-dom';

function ShowCart({restaurantStatus}) {
  const [showCart, setShowCart] = useState(false);
  const navigate=useNavigate();
  const handleToggleCart = () => {
    setShowCart(!showCart); // Toggle the value of showCart
  };
  const cartImg = localStorage.getItem("cartQTYImg")
  const cartQty = localStorage.getItem("cartQty");
  const Clientname = localStorage.getItem("clientName")
  const finalAmt = localStorage?.getItem("totalPrice") || 0;
  // console.log(restaurantStatus);
  const handleMenu=()=>{
    const prevCartMenu=localStorage.getItem("cartClientPath");
    navigate(`/${prevCartMenu}/menu`);
  }
  return (  
    <>
   {cartQty && cartQty!== "0" && (
    <div className="showcart">
      <div className='top-to-btmss d-block d-md-none'>
        <button className="icon-position icon-style" onClick={handleToggleCart}>
          <IoMdCart />
          <span>{cartQty}</span>
        </button>

        <div className={`showcartitem ${showCart ? 'show' : ''}`}>
          <div className="showcartitemchild" >
            <div className="shocartimg" onClick={handleMenu}>
            <FaShoppingCart/>
              {/* <img src="https://fusioneposfiles.s3-accelerate.amazonaws.com/img/menu/16796554136756.jpg" alt="" /> */}
              <div className="restname">
                <h6> {Clientname}</h6>
                <div className="menuitme">
                <h6> {cartQty} item</h6> |
                <h6>  view Menu <IoMdArrowDropright/> </h6>
                </div>
              </div>
            </div>

            <div className="cartbtn" onClick={handleMenu}>
              <h6>£{finalAmt}</h6>
              <h6>  view Cart </h6>
            </div>
            <div className="closicon" onClick={handleToggleCart}><IoMdClose /></div>
          </div>
        </div>
      </div>
    </div>
   )
  }
  </>
  );
}

export default ShowCart;
