import FacebookLogin from 'react-facebook-login'
import { connect } from 'react-redux';
import { login,setLoggedIn } from '../actions/login/authFacebook';

// const your_app_id="1331300873695941";
const your_app_id="1868605456900653";


function FacebookSignIn({ login,onClosePopup }) {
    
    const responseFacebook = (response) => {
      console.log('Facebook User:', response);
      // Call the provided function on successful login
      if (response.accessToken) {
        const postData = {
            verfication_code: response.id,
            email: response.email?response.email:'',
            login_type: '2',
            first_name:response.name,
            last_name:''
        };
        login(postData);
        // onClosePopup();
      }
    };
  
    return (
        
      // <FacebookLogin
      // appId={your_app_id}
      // autoLoad={false}
      // fields="name,email,picture"
      // scope="public_profile,email"
      // callback={responseFacebook}
      // icon="fa-facebook" />

      <FacebookLogin
              size="medium"
              autoLoad={false}
              appId={your_app_id}
              fields="name,email"
              scope="public_profile,email"
              callback={responseFacebook}
              isMobile={false}
              disableMobileRedirect={true}
               icon="fa-facebook"
/>
       

    );
  }

  const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.userdata.isLoggedIn,
      userData: state.userdata.userData,
    };
  };
  
  const mapDispatchToProps = {
    login,
    setLoggedIn,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(FacebookSignIn);