import { useState } from "react";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "./css/appSign.scss";
import google_svg from "../../pages/sign-in/svg/Google__G__Logo.svg";
import apple_svg from "../../pages/sign-in/svg/apple.svg";
// import facebook_svg from "./svg/Facebook-f_Logo-Blue-Logo.wine.svg";
import path_svg from "../../pages/sign-in/svg/Path 17008.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import lotti_anmi from "../../pages/sign-in/lottifile/mobile-otp-approved.json";
import rejected_anmi from "../../pages/sign-in/lottifile/mobile-otp-rejected.json";
import { useContext } from "react";
import { AiOutlineLeftCircle } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import ThemeContext from "../../common/theme/components/contexts/themecontexts";
// import Login from "../../components/sso-login";
import AppLogin from "../../components/sso-app-login"
import { useEffect } from "react";
import { gapi } from "gapi-script";
import FacebookLogin from "../../components/facebookLogin";
import AppleAppLogin from "../../components/appleLogin-app";
import { login, setLoggedIn } from "../../actions/login/authActions";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { OrderFlow } from "../../App";
import { Helmet } from "react-helmet";
import { FaArrowLeft } from "react-icons/fa6";
import jwtEncode from 'jwt-encode';
import "../../pages/myaccount/components/profile/otp-screen.scss";


function AppSigin({ login, setLoggedIn, userData, loginFailure }) {
    const { theme } = useContext(ThemeContext);
    const { loginPopupShow, setLoginPopupShow, moveToCheck, setMoveToCheck, currentPage, setCurrentPage, appLoginPopupShow, setAppLoginPopupShow } =
        OrderFlow();
    const handleClose = () => setLoginPopupShow(false);

    const location = useLocation();
    const [username, setUsername] = useState("");
    const [otp, setOtp] = useState(["", "", "", ""]);
    // const [showOtpModal, setShowOtpModal] = useState(false);
    // const [LogCount,setLogCount]=useState(0);
    const [otpError, setOtpError] = useState(false);
    const [resendEnabled, setResendEnabled] = useState(false);
    const [valid, setValid] = useState("");
    const [buttonstatus, setButtonstatus] = useState(false);


    const [timer, setTimer] = useState(30);
    const [loginError, setLoginError] = useState(null);
    const [loginBtn, setLoginBtn] = useState(true);

    const [inputType, setInputType] = useState("email");

    const handleShow = () => {
        setCurrentPage("signIn");
        setLoginPopupShow(true);
    };
    // useEffect(() => {
    //   // This will run every time logCount changes
    //   if (LogCount > 0) {
    //     console.log("Updated Log Count:", LogCount);
    //     localStorage.setItem("Log_IP_C", LogCount);
    //   }
    // }, [LogCount]);
    //  useEffect(()=>{
    //   fetch('https://api.ipify.org?format=json')
    // .then(response => response.json())
    // .then(data => {
    //   setLogCount(prev=>prev+1);
    //   console.log('IP Address:', data.ip);
    //   localStorage.setItem("Log_IP_Address",data.ip);
    //   localStorage.setItem("Log_IP_C",LogCount);
    // })
    // .catch(error => {
    //   console.error('Error fetching IP address:', error);
    // });
    // },[]);

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(countdown);
        }

        if (timer <= 0) {
        }
    }, [timer]);

    useEffect(() => {
        setLoginError(loginFailure?.message);
        setButtonstatus(false);
    }, [loginFailure]);

    const reduxDispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoginError(null);

        var mailFormat =
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{11})+$/;
        if (username === "") {
            setValid(" Please enter your Email or Phone Number  ");
            setLoginBtn(true);
        } else if (!mailFormat.test(username)) {
            setLoginBtn(true);
            setValid("EmailAddress/PhoneNumber is not valid.");
            return false;
        } else {
            setButtonstatus(true);
            const secretKey = "FUSION-2024"; // Replace with your secret key
            const secret = "FUSION-2024"; // Define your secret key
            const payload = {
                user_name: username,
                country_code: "GB",
                exp: Math.floor(Date.now() / 1000) + (86400 * 20) // Token expiration time (1 hour)
            };
            const jwt = jwtEncode(payload, secret); // Encode the data using jwtEncode function
            console.log('Encoded JWT:', jwt);
            // Here you can use the encoded JWT as needed
            // const token = jwt.sign(payload, secretKey, { algorithm: 'HS256' });
            // localStorage.setItem('token', jwt);
            const datas = {
                data: jwt
            }
            login(datas);
            // if(LogCount===1){
            //   login(datas);
            // }
            setTimer(30);
        }
    };

    const handleOtpSubmit = (e) => {
        console.log("otp submit");

        e.preventDefault();
        let newotp = otp?.toString()?.replace(/,/g, "");
        if (newotp === userData.otp.toString()) {
            handleClose();
            setOtpError(false);
            reduxDispatch(setLoggedIn());
            // navigate("/myaccount");
            setOtp(["", "", "", ""]);
            if (moveToCheck) {
                setMoveToCheck(false);
                localStorage.setItem("moveToCheck", false);
                const path = localStorage.getItem("clientPath");
                navigate(`/${path}/menu`);
            }
            else{
                navigate(`/`);
            }
        } else {
            setOtpError(true);
            setOtp(["", "", "", ""]);
            setOtpError("Invalid OTP");
            setResendEnabled(true);
        }
    };

    useEffect(() => {
        if (
            userData &&
            userData.status &&
            userData.otp &&
            userData.customerId &&
            userData.userId
        ) {
            setButtonstatus(false);
            setCurrentPage("otp");
            setResendEnabled(false);
            const timer = setTimeout(() => {
                setResendEnabled(true);
            }, 30000);
            return () => clearTimeout(timer);
        }
    }, [userData]);

    const handleResendOtp = () => {
        setTimer(30);
        setResendEnabled(false);
        setOtpError(false);
        // login({ user_name: username, country_code: "GB" });
        const secretKey = "FUSION-2024"; // Replace with your secret key
        const secret = "FUSION-2024"; // Define your secret key
        const payload = {
            user_name: username,
            country_code: "GB",
            exp: Math.floor(Date.now() / 1000) + (86400 * 20) // Token expiration time (1 hour)
        };
        const jwt = jwtEncode(payload, secret); // Encode the data using jwtEncode function
        console.log('Encoded JWT:', jwt);
        // Here you can use the encoded JWT as needed
        // const token = jwt.sign(payload, secretKey, { algorithm: 'HS256' });
        // localStorage.setItem('token', jwt);
        const datas = {
            data: jwt
        }
        login(datas);
        // if(LogCount===1){
        // login(datas);
        // }
        // Code to resend OTP
        // You can dispatch an action or call an API to resend the OTP
        // After successful OTP resend, you can enable the resend option again
        // setTimeout(() => {
        //   setResendEnabled(true);
        // }, 30000);
    };

    //////// for otp /////////////

    const clientId =
        "803594775718-cotov69rgs0fe4hapbesv7se2q6pab2l.apps.googleusercontent.com";
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: clientId,
                scope: "",
            });
        }

        gapi.load("client:auth2", start);
    });

    useEffect(() => {
        console.log(username);
    }, [username]);

    const handleChange = async (e) => {
        setLoginBtn(false);
        setUsername(e.target.value);
        setValid("");
        setLoginError("");
        if (e.target.value.match(/^[0-9]{11}$/)) {
            setInputType("phone");
        } else {
            setInputType("email");
        }
    };

    function handleContinueGuest() {
        if (location.pathname.includes("/menu")) {
            const path = localStorage.getItem("clientPath");
            navigate(`/${path}/checkout`);
            handleClose();
        } else {
            handleClose();
        }
    }
    const handleBackPage = () => {
        setLoginBtn(true);
        setCurrentPage("signIn");
    };
    const handleCloseAppLogin=()=>{
        setAppLoginPopupShow(false);
    }
    const HomePage = location.pathname.endsWith("/menu") || location.pathname.endsWith("/checkout");
    return (
        <>
            <Helmet>
                <style type="text/css">{`
               @media only screen and (max-width: 575px) {
               .sign_full_app .modal-content {
                  height: 100% !important;
                    width:100%;
                  border-radius:0px !important;
                }
                #loginpopups.modal-dialog-centered {
                height:100%;
                width:100%
                }
                }

            `}
                </style>

            </Helmet>
            <Modal
                centered
                show={appLoginPopupShow}
                onHide={handleClose}
                className={`sign_full_app ${theme === "dark" ? "dark-theme" : ""}`}
                backdrop="static"
                keyboard={false}
                id="loginpopups"
                style={{ height: '100%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {currentPage === "signIn" && (
                            <div className="full_content">

                                <div>
                                <span className="close-btn" data-Fus="fus-test-CloseBtn" onClick={handleCloseAppLogin}>
                                    <FaArrowLeft className="back-app-home" style={{pading:'6px'}}/>
                                </span>
                                </div>
                                <div className="main_screen">
                                    <p className="sign_heading"> Sign Up or Sign In</p>
                                    <div className="email_btns">
                                        <form onSubmit={handleSubmit}>
                                            <div className="email_input">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Enter your Email"
                                                    data-Fus="fus-test-Inputfieldsigin"
                                                />
                                                {valid || loginError ? (
                                                    <>
                                                        <p className="error-code">
                                                            {valid ? valid : loginError}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className="green_btn" >
                                                <button data-Fus="fus-test-LoginBtn" disabled={loginBtn ? true : false}>
                                                    {" "}
                                                    {buttonstatus ? (
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            variant="success"
                                                        />
                                                    ) : (
                                                        "Continue"
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                        <div className="lines-lines">
                                            <div className="lines">
                                                <div className="or">or</div>
                                            </div>
                                        </div>

                                        <div className="google_btn" id="signInButton" data-Fus="fus-test-GoogleLoginbtn">
                                            <AppLogin onClosePopup={handleClose} />
                                        </div>
                                        <div className="apple_btn" data-Fus="fus-test-appleLoginbtn">
                                            <AppleAppLogin onClosePopup={handleClose} />
                                        </div>
                                        {/* <div className="facebook_btn">
                      
                      <FacebookLogin onClosePopup={handleClose} />
                    </div> */}
                                        {HomePage && (
                                            <div className="guest_btn">
                                                <button onClick={handleContinueGuest} data-Fus="fus-test-GuestLoginBtn">
                                                    <img src={path_svg} alt="" />
                                                    Continue as Guest
                                                </button>
                                            </div>)
                                        }

                                        <div className="lines-lines">
                                            <div className="lines">
                                                <div className="or">or</div>
                                            </div>
                                        </div>

                                        <div className="tc">
                                            <div className="grey">
                                                By continuing you agree to our{" "}
                                                <Link to={"/general-terms"}>
                                                    <span className="red" onClick={handleClose}>
                                                        T&C
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                                <div className="otp_screen"></div>
                            </div>
                        )}
                        {currentPage === "otp" && (
                            <div className="sign_otp_full">
                                <div className="arrow_back_key">
                                    <div className="arrow_back_key" data-Fus="fus-test-BackbtnArrow" onClick={handleBackPage}>
                                        <AiOutlineLeftCircle />
                                    </div>
                                    <div className="lotti_file">

                                        {otpError ? (
                                            <>
                                                <Player autoplay loop src={rejected_anmi}></Player>
                                            </>
                                        ) : (
                                            <>
                                                <Player autoplay loop src={lotti_anmi}></Player>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <p className="otp_heading">Sign Up</p>
                                <p className="subject_otp">
                                    Enter the OTP received to your{" "}
                                    {inputType === "phone" ? "mobile" : "email"}
                                </p>
                                <form onSubmit={handleOtpSubmit}>
                                    <div className="otp_input_fields">
                                        <OTPInput
                                            otp={otp}
                                            setOtp={setOtp}
                                            otpError={otpError}
                                            setOtpError={setOtpError}
                                            data-Fus="fus-test-OtpInputField"
                                        />
                                    </div>
                                    <div className="sign_grey"  >
                                        <button data-Fus="fus-test-SignBtn" type="submit">Continue</button>
                                    </div>
                                </form>
                                {timer > 0 &&
                                    <p className="time_otp">00:{timer}</p>
                                }
                                {resendEnabled ? (
                                    <p className="time_otp">
                                        Please enter the valid OTP or
                                        <span className="red" onClick={handleResendOtp} data-Fus="fus-test-ResendOTpBtn">
                                            {" "}
                                            RESEND OTP
                                        </span>
                                    </p>
                                ) : (
                                    <></>
                                )}

                                <p
                                    className="other_login"
                                    data-Fus="fus-test-TryOtherLoginBtn"
                                    onClick={() => setCurrentPage("login_google")}
                                >
                                    Try other Login
                                </p>
                            </div>
                        )}

                        {currentPage === "login" && (
                            <div className="sign_otp_full">
                                <div className="arrow_back_key">
                                    <div
                                        className="arrow_back_key"
                                        data-Fus="fus-test-OTPBtn"
                                        onClick={() => setCurrentPage("otp")}
                                    >
                                        <AiOutlineLeftCircle />
                                    </div>
                                    <div className="lotti_file">
                                        <Player autoplay loop src={rejected_anmi}></Player>
                                    </div>
                                </div>
                                <p className="otp_heading">Sign Up</p>
                                <p className="subject_otp">
                                    Enter the OTP received to your email
                                </p>
                                <div className="otp_input_fields">
                                    <input type="text" placeholder="" data-Fus="fus-test-OtpInputField" />
                                    <input type="text" placeholder="" data-Fus="fus-test-OtpInputField" />
                                    <input type="text" placeholder="" data-Fus="fus-test-OtpInputField" />
                                    <input type="text" placeholder="" data-Fus="fus-test-OtpInputField" />
                                </div>
                                <div className="sign_grey">
                                    <button id="border_red" data-Fus="fus-test-SignInBtn" >Sign In</button>
                                </div>

                                <p className="time_otp">
                                    Please enter the valid OTP or
                                    <span className="red" data-Fus="fus-test-ResendOtpBtn"> RESEND OTP</span>
                                </p>

                                <p
                                    className="other_login"
                                    data-Fus="fus-test-TryInothertext"
                                    onClick={() => setCurrentPage("login_google")}
                                >
                                    Try other Login
                                </p>
                            </div>
                        )}
                        {currentPage === "login_google" && (
                            <>
                                <div className="arrow_back_key">
                                    <div
                                        className="arrow_back_key"
                                        data-Fus="fus-test-Backarrow"
                                        onClick={() => setCurrentPage("signIn")}
                                    >
                                        <AiOutlineLeftCircle />
                                    </div>
                                </div>
                                <div className="multi_log">
                                    <div className="google_btn" id="signInButton" data-Fus="fus-test-Googleloginbtn" >
                                        <AppLogin onClosePopup={handleClose} />
                                    </div>
                                    <div className="apple_btn">
                                        <button data-Fus="fus-test-Appleloginbtn">
                                            <img src={apple_svg} alt="" />
                                            Continue with Apple
                                        </button>
                                    </div>
                                    {/* <div className="facebook_btn">
                    <button>
                      <img src={facebook_svg} alt="" />
                      Continue with Facebook
                    </button>
                  </div>  */}
                                    <div className="guest_btn">
                                        <button onClick={handleContinueGuest}
                                            data-Fus="fus-test-Guestloginbtn">
                                            <img src={path_svg} alt="" />
                                            Continue as Guests
                                        </button>
                                    </div>

                                    <div className="tc">
                                        <div className="grey">
                                            By continuing you agree to our{" "}
                                            <span className="red" onClick={handleClose} >
                                                <Link to={"/general-terms"} data-Fus="fus-test-TandCtext">T&C</Link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                    </>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
}

//otp input
const OTPInput = ({ otp, setOtp, otpError, setOtpError }) => {
    const handleOTPChange = (e, index) => {
        setOtpError(false);
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newOTP = [...otp];
            newOTP[index] = value;
            setOtp(newOTP);
            if (index < 3 && value.length === 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    return (
        <>
            <div className="input-box">
                {otp?.map((digit, index) => (
                    <input
                        key={index}
                        value={digit}
                        id={`otp-input-${index}`}
                        type="number"
                        maxLength="1"
                        style={{
                            textAlign: "center",
                        }}
                        data-Fus="fus-test-OtpchangeBtn"
                        onChange={(e) => handleOTPChange(e, index)}
                        className={otpError ? "error" : ""}
                    />
                ))}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.userdata.isLoggedIn,
        userData: state.userdata.userData,
        loginFailure: state.userdata.error,
    };
};

const mapDispatchToProps = {
    login,
    setLoggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSigin);

// export default SignIn;
