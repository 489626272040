// src/reducers/myaccount/orderstatus/myAccountFeedbackReducer.js
import {
    GET_TABLEBOOKING_SUCCESS,
    GET_TABLEBOOKING_FAILURE
  } from "../../../actions/types";
  
  const initialState = {
    tableBooking: null,
    error: null,
  };
  
  export const tableBookingReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TABLEBOOKING_SUCCESS:
        return {
          ...state,
          tableBooking: action.payload,
          error: null,
        };
      case GET_TABLEBOOKING_FAILURE:
        return {
          ...state,
          tableBooking: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  