import { ClientFeedbackApi } from "../../api/feedback/customerFeedBackApi";
import {
  CLIENT_FEEDBACK_REQUEST,
  CLIENT_FEEDBACK_SUCCESS,
  CLIENT_FEEDBACK_FAILURE,
  CLIENT_FEEDBACK_RESET
} from "../types";

export const ClientFeedbackRequest = () => ({
  type: CLIENT_FEEDBACK_REQUEST,
});

export const ClientFeedbackSuccess = (pageData) => ({
  type: CLIENT_FEEDBACK_SUCCESS,
  payload: pageData,
});

export const ClientFeedbackFailure = (error) => ({
  type: CLIENT_FEEDBACK_FAILURE,
  payload: error,
});

export const ResetFeedBack=()=>({
  type: CLIENT_FEEDBACK_RESET,
});

export const ClientFeedbackDetail = (formData) => {
  return (dispatch) => {
    dispatch(ClientFeedbackRequest());
    ClientFeedbackApi(formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          dispatch(ClientFeedbackSuccess(response.data.data));
        } else {
          dispatch(ClientFeedbackFailure(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(ClientFeedbackFailure(error.message));
      });
  };
};
