import { addonDetail } from "./addonAction";
import { applyDiscount, removeDiscount } from "./offerAction";
import { CART_DETAIL_SUCCESS, CART_DETAIL_FAILURE } from "../types";

export const getCartSuccess = (cartData) => ({
  type: CART_DETAIL_SUCCESS,
  payload: cartData,
});

export const getCartFailure = (error) => ({
  type: CART_DETAIL_FAILURE,
  payload: error,
});

export const cartStoreLocal = () => {
  return (dispatch) => {
    const localDataValue = localStorage.getItem("cart");
    dispatch(getCartSuccess(localDataValue));
  };
};

export const finalSelectItems = (
  postDatavalue,
  discount,
  getOrderMode,
  itemImage,
  itemDesc,
  itemAllowCoupon,
  delivery_price,
  collection_price
) => {
  addonDetail(postDatavalue)
    .then((response) => {
      console.log(response.message);
      cartAddonItem(
        response.message,
        discount,
        getOrderMode,
        itemImage,
        itemDesc,
        itemAllowCoupon,
        delivery_price,
        collection_price
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

export const plusCartStore = (updatedCart, offer, getOrderMode) => {
  let cartUpdated = false;
  if (updatedCart) {
    const existingQty = parseFloat(localStorage.getItem("cartQty")) || 0;
    const newQtyFinal = parseFloat(existingQty + 1);
    localStorage.setItem("cartQty", newQtyFinal);
    const existingArray = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = existingArray.findIndex(
      (item) =>
        item.itemId === updatedCart.itemId &&
        item.addonName === updatedCart.addonName
    );
    if (existingItemIndex !== -1) {
      existingArray[existingItemIndex].count += 1;
      const existingPrice = parseFloat(existingArray[existingItemIndex].price);
      const updatedPrice = parseFloat(
        existingArray[existingItemIndex].actual_price
      );
      const itemPriceAdd = existingPrice + updatedPrice;
      existingArray[existingItemIndex].price = itemPriceAdd.toFixed(2);
    }
    const existingTotal = parseFloat(localStorage.getItem("totalPrice")) || 0;
    const newPrice = parseFloat(existingArray[existingItemIndex].actual_price);
    const newTotal = parseFloat(existingTotal + newPrice);
    localStorage.setItem("totalPrice", newTotal.toFixed(2));
    localStorage.setItem("cart", JSON.stringify(existingArray));

    const selectedGift = localStorage.getItem("gift");
    if (selectedGift) {
      // remove discount while select gift function
      removeDiscount();
    } else {
      // Apply discount offer function
      applyDiscount(offer, getOrderMode);
    }

    cartUpdated = true;
    return cartUpdated;
  }
};

export const addBulkCart = (updatedCart, offer, getOrderMode) => {
  const itemNotes = localStorage.getItem("itemNotes") || "";
  const notes = itemNotes ? JSON.parse(itemNotes) : "";
  let cartUpdated = false;
  if (updatedCart) {
    let existingCartName = "";
    let existingCart = localStorage.getItem("cart");
    let exitClientPath = localStorage.getItem("cartClientPath");
    let curentClientPath = localStorage.getItem("cartClientPath");
    if (existingCart === "undefined") {
      localStorage.setItem("cart", "[]");
      existingCart = localStorage.getItem("cart");
    }
    if (existingCart) {
      const parsedCart = JSON.parse(existingCart) || [];
      // Parse the JSON string
      const clientId = localStorage.getItem("clientId");

      if (parsedCart.length > 0 && clientId !== null) {
        // Check if clientId exists and the parsedCart is an array
        const existingCartIndex = parseFloat(parsedCart[0]["clientId"]);
        existingCartName = parsedCart[0]["clientName"];

        if (parseFloat(clientId) !== existingCartIndex) {
          localStorage.setItem("cartQty", "0");
          localStorage.setItem("cart", "[]");
          localStorage.setItem("totalPrice", "0");
          updatedCart.count = 1;
        }
      }
    }
    
    const order_Mode = localStorage.getItem("orderMode");
    const updatedCartCount = updatedCart.count ? updatedCart.count : 1;
    const updatedorderModePrice = order_Mode === "Delivery" ? updatedCart.delivery_price : updatedCart.collection_price;
    const cartStoreDate = {
      itemId: updatedCart.itemId,
      itemName: updatedCart.itemName,
      addonName:
        updatedCart.gift || updatedCart.point ? "" : updatedCart.addonName,
      price: updatedCart.addon ? (updatedorderModePrice * updatedCartCount).toFixed(2) : (updatedorderModePrice * updatedCartCount).toFixed(2),
      actual_price: updatedorderModePrice,
      count: updatedCartCount,
      gift: updatedCart.gift ? 1 : 0,
      loyalty: updatedCart.point ? 1 : 0,
      addon: updatedCart.addon,
      addonFirstId: updatedCart.addonFirstId,
      instruction:
        notes && updatedCart.itemId === notes.itemId ? notes.note : "",
      clientId: localStorage.getItem("clientId"),
      clientName: localStorage.getItem("clientName"),
      itemImage: updatedCart.itemImage,
      itemDesc: updatedCart.itemDesc,
      itemAllowCoupon: updatedCart.itemAllowCoupon,
      delivery_price: updatedCart.delivery_price,
      collection_price: updatedCart.collection_price
    };

    const existingArray = JSON.parse(localStorage.getItem("cart")) || [];
    const itemExists = existingArray.some(
      (item) =>
        item.itemId === cartStoreDate.itemId &&
        cartStoreDate.loyalty === item.loyalty &&
        cartStoreDate.gift === item.gift &&
        cartStoreDate.addonName === item.addonName
    );
    if (itemExists) {
      const existingItemIndex = existingArray.findIndex(
        (item) =>
          item.itemId === cartStoreDate.itemId &&
          cartStoreDate.loyalty === item.loyalty &&
          cartStoreDate.gift === item.gift &&
          cartStoreDate.addonName === item.addonName
      );

      if (existingItemIndex !== -1) {
        existingArray[existingItemIndex] = {
          ...existingArray[existingItemIndex],
          count: cartStoreDate.count,
          price: cartStoreDate.price,
        };
      }
      localStorage.setItem("cart", JSON.stringify(existingArray));
    } else {
      existingArray.push(cartStoreDate);
      localStorage.setItem("cart", JSON.stringify(existingArray));
    }
    const newCartArray = JSON.parse(localStorage.getItem("cart")) || [];
    const newTotalCount = newCartArray.reduce(
      (countAccumulator, currentItem) => {
        return countAccumulator + currentItem.count;
      },
      0
    );

    const newTotalPrice = newCartArray.reduce(
      (priceAccumulator, currentItem) => {
        return priceAccumulator + parseFloat(currentItem.price);
      },
      0
    );
    let cartPath = localStorage.getItem("clientPath");
    let CartName = localStorage.getItem("clientName");
    localStorage.setItem("cartQty", newTotalCount);
    localStorage.setItem("totalPrice", newTotalPrice.toFixed(2));
    localStorage.setItem("cartClientPath", cartPath);
    localStorage.setItem("cartName", CartName);

    const selectedGift = localStorage.getItem("gift");
    if (selectedGift) {
      // remove discount while select gift function
      removeDiscount();
    } else {
      // Apply discount offer function
      applyDiscount(offer, getOrderMode);
    }

    cartUpdated = true;
    return cartUpdated;
  }
};

export const cartStore = (updatedCart, offer, getOrderMode) => {
  const itemNotes = localStorage.getItem("itemNotes") || "";
  const notes = itemNotes ? JSON.parse(itemNotes) : "";
  let cartUpdated;
  if (updatedCart) {
    const cartStoreDate = {
      itemId: updatedCart.itemId,
      itemName: updatedCart.itemName,
      addonName:
        updatedCart.gift || updatedCart.point ? "" : updatedCart.addonName,
      price: updatedCart.price,
      actual_price: updatedCart.price,
      count: 1,
      gift: updatedCart.gift ? 1 : 0,
      loyalty: updatedCart.point ? 1 : 0,
      addon: updatedCart.addon,
      addonFirstId: 0,
      instruction:
        notes && updatedCart.itemId === notes.itemId ? notes.note : "",
      clientId: parseFloat(localStorage.getItem("clientId")),
      clientName: localStorage.getItem("clientName"),
      itemAllowCoupon: updatedCart.itemAllowCoupon,
      delivery_price: updatedCart.delivery_price,
      collection_price: updatedCart.collection_price
    };

    const existingQty = parseFloat(localStorage.getItem("cartQty")) || 0;
    const newQtyFinal = parseFloat(existingQty + 1);
    localStorage.setItem("cartQty", newQtyFinal);
    const existingArray = JSON.parse(localStorage.getItem("cart")) || [];
    const itemExists = existingArray.some(
      (item) =>
        item.itemId === updatedCart.itemId &&
        cartStoreDate.loyalty === item.loyalty &&
        cartStoreDate.gift === item.gift &&
        cartStoreDate.addonName === item.addonName
    );

    if (itemExists) {
      const existingItemIndex = existingArray.findIndex(
        (item) =>
          item.itemId === updatedCart.itemId &&
          cartStoreDate.loyalty === item.loyalty &&
          cartStoreDate.gift === item.gift &&
          cartStoreDate.addonName === item.addonName
      );
      if (existingItemIndex !== -1) {
        existingArray[existingItemIndex].count += 1;
        const existingPrice = parseFloat(
          existingArray[existingItemIndex].price
        );
        const updatedPrice = parseFloat(
          existingArray[existingItemIndex].actual_price
        );
        const itemPriceAdd = existingPrice + updatedPrice;
        existingArray[existingItemIndex].price = itemPriceAdd.toFixed(2);
        existingArray[existingItemIndex]["addon"].forEach((item) => {
          item.qty = String(parseInt(item.qty) + 1);
        });
      }
      localStorage.setItem("cart", JSON.stringify(existingArray));
    } else {
      existingArray.push(cartStoreDate);
      localStorage.setItem("cart", JSON.stringify(existingArray));
    }
    const newCartArray = JSON.parse(localStorage.getItem("cart")) || [];
    const newTotalCount = newCartArray.reduce(
      (countAccumulator, currentItem) => {
        return countAccumulator + currentItem.count;
      },
      0
    );

    const newTotalPrice = newCartArray.reduce(
      (priceAccumulator, currentItem) => {
        return priceAccumulator + parseFloat(currentItem.price);
      },
      0
    );
    cartUpdated = false;
    localStorage.setItem("cartQty", newTotalCount);
    localStorage.setItem("totalPrice", newTotalPrice.toFixed(2));
  }
  const selectedGift = localStorage.getItem("gift");
  if (updatedCart.gift === 1 || selectedGift) {
    // remove discount while select gift function
    removeDiscount();
  } else {
    // Apply discount offer function
    applyDiscount(offer, getOrderMode);
  }
};

export const cartRemoveItem = (updatedCart, offer, getOrderMode) => {
  const existingQty = parseFloat(localStorage.getItem("cartQty")) || 0;
  if (parseFloat(existingQty) > 1) {
    const newQtyFinal = parseFloat(existingQty - 1);
    const existingArray = JSON.parse(localStorage.getItem("cart")) || [];
    const itemExists = existingArray.some(
      (item) =>
        item.itemId === updatedCart.itemId &&
        item.loyalty === updatedCart.loyalty &&
        item.addonName === updatedCart.addonName
    );
    if (itemExists) {
      const existingItemIndex = existingArray.findIndex(
        (item) =>
          item.itemId === updatedCart.itemId &&
          item.loyalty === updatedCart.loyalty &&
          item.addonName === updatedCart.addonName
      );
      if (existingItemIndex !== -1) {
        existingArray[existingItemIndex].count -= 1;
        const existingPrice = parseFloat(
          existingArray[existingItemIndex].price
        );
        const updatedPrice = parseFloat(
          existingArray[existingItemIndex].actual_price
        );
        const itemPriceAdd = existingPrice - updatedPrice;
        existingArray[existingItemIndex].price = itemPriceAdd.toFixed(2);
      }
      const existingTotal = parseFloat(localStorage.getItem("totalPrice")) || 0;
      const newPrice = parseFloat(
        existingArray[existingItemIndex].actual_price
      );
      const newTotal = parseFloat(existingTotal - newPrice);
      localStorage.setItem("cart", JSON.stringify(existingArray));
      localStorage.setItem("cartQty", newQtyFinal);
      localStorage.setItem("totalPrice", newTotal.toFixed(2));
    }
  }
  const selectedGift = localStorage.getItem("gift");
  if (selectedGift) {
    // remove discount while select gift function
    removeDiscount();
  } else {
    // Apply discount offer function
    applyDiscount(offer, getOrderMode);
  }
};

export const cartRemoveAllItem = (updatedCart, offer, getOrderMode) => {
  const existingQty = parseFloat(localStorage.getItem("cartQty")) || 0;
  const newQtyFinal = parseFloat(existingQty - updatedCart.count);
  localStorage.setItem("cartQty", newQtyFinal);

  const existingTotal = parseFloat(localStorage.getItem("totalPrice")) || 0;
  const newTotal = parseFloat(existingTotal - updatedCart.price);
  localStorage.setItem("totalPrice", newTotal.toFixed(2));

  let existingArray = JSON.parse(localStorage.getItem("cart")) || [];
  existingArray = existingArray.filter(
    (item) =>
      item.itemId !== updatedCart.itemId ||
      item.loyalty.toString() !== updatedCart.loyalty.toString() ||
      item.gift.toString() !== updatedCart.gift.toString() ||
      item.addonName !== updatedCart.addonName
  );
  localStorage.setItem("cart", JSON.stringify(existingArray));
  // Apply discount offer function
  applyDiscount(offer, getOrderMode);
};

export const cartAddonItem = (
  updatedAddonItemCart,
  offer,
  getOrderMode,
  itemImage,
  itemDesc,
  itemAllowCoupon,
  delivery_price,
  collection_price,
) => {
  const cartStoreDate = {
    itemId: updatedAddonItemCart.orderItemId,
    itemName: updatedAddonItemCart.orderItemName,
    price: updatedAddonItemCart.orderItemPrice,
    actual_price: updatedAddonItemCart.orderItemPrice,
    count: updatedAddonItemCart.count,
    addonFirstId: updatedAddonItemCart.addon,
    addonName: updatedAddonItemCart.selectedItem
      .map((item) => item.items)
      .join(", "),
    addon: updatedAddonItemCart.selectedItem.map((item) => ({
      id: item.id,
      extra: item.extra,
      qty: "1",
    })),
    itemImage: itemImage,
    itemDesc: itemDesc,
    itemAllowCoupon: itemAllowCoupon,
    delivery_price: updatedAddonItemCart.orderDeliveryPrice,
    collection_price: updatedAddonItemCart.orderCollectionPrice
  };
  // cartStore(cartStoreDate, offer, getOrderMode);
  addBulkCart(cartStoreDate, offer, getOrderMode);
};

export const cartPrice = () => {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];

  if (cart.length > 0) {
    const orderMode = localStorage.getItem("orderMode");

    // Use `map` to create a new array with updated `actual_price`
    const finalCart = cart.map((v) => {
      const deliveryPrice = parseFloat(v.delivery_price) || 0;
      const collectionPrice = parseFloat(v.collection_price) || 0;
      const selectedPrice = orderMode === "Delivery" ? deliveryPrice : collectionPrice;

      return {
        ...v,
        price: (v.count * selectedPrice).toFixed(2), // Calculate and format total price
        actual_price: selectedPrice.toFixed(2), // Format the selected single item price
      };
    });
    const newTotalPrice = finalCart.reduce(
      (priceAccumulator, currentItem) => {
        return priceAccumulator + parseFloat(currentItem.price);
      },
      0
    );
    localStorage.setItem("totalPrice", newTotalPrice.toFixed(2));
    // Store the updated cart back in localStorage
    localStorage.setItem("cart", JSON.stringify(finalCart));
  }
};

