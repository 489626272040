import React, { useState, useEffect, useContext } from "react";
import './Yourorder.scss';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Container, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from "react-router-dom";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import { connect } from "react-redux";
import { getOrderHistory } from "../../../../actions/myaccount/order/orderHistoryActions";
import { orderAgainApi } from "../../../../api/myaccount/order/orderAgainApi";
import OrderDetailJson from "../myorder/orderdetails.json";
import {
  MdOutlineArrowBackIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

export const reOrder = async (formData) => {
  try {
    const response = await orderAgainApi(formData);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const ITEMS_PER_PAGE = 6;
const Yourorder = ({
  orderhistory,
  orderagain,
  error,
  userData,
  getOrderHistory,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  // Calculate the index range for items on the current page
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = orderhistory?.message?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(orderhistory?.message?.length / ITEMS_PER_PAGE);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const { theme } = useContext(ThemeContext);
  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/BlogImages/My-accoount-arrow.png';
  const IMAGE_URL = "https://fusionbucket.co.uk/My-account/Screenshot 2024-10-24 at 6.57.50 PM 1.png";
  const [searchOrder, setSearchOrder] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 992;
  const location = useLocation();
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(()=>{
   console.log(searchOrder);
   
  },[searchOrder])


  const OrderDetails = OrderDetailJson && OrderDetailJson;

  const OrderDetailURL = "../orderdetail";
  const navigate = useNavigate();
  const navigateToOrderDetail = async (event) => {
    const GetOId = event.target.getAttribute("Oid");
    const GetCId = event.target.getAttribute("Cid");
    const GetPath = event.target.getAttribute("Path");
    navigate(OrderDetailURL, { state: { GetOId, GetCId, GetPath } });
  };

  const OrderStatusURL = "../orderstatus";
  const navigateToOrderStatus = (event) => {
    const GetOId = event.target.getAttribute("Oid");
    const GetCId = event.target.getAttribute("Cid");
    navigate(OrderStatusURL, { state: { GetOId, GetCId } });
  };

  // Responsive Navigate
  const navigateto = useNavigate();
  if (!location.pathname.includes("/myaccount") && width > breakpoint) {
    navigateto("/myaccount/myorder");
  }

  // get the data from API
  useEffect(() => {
    getOrderHistory({ customer_id: userData.customerId, body: "Your body" });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const orderhistorydatas = orderhistory?.message; // Assign to a separate variable
  const handleOrderAgain = (orderId, client_id, clientPath) => {
    const formData = {
      client_id: client_id,
      order_id: orderId,
    };
    console.log(formData);
    reOrder(formData)
      .then((response) => {
        console.log(response);
        if (response.status === false) {
          console.log(response.message);
        } else {
          const orderAgainData = JSON.stringify(response?.message?.item);
          localStorage.setItem("cart", orderAgainData);
          const totalPrice = response?.message?.item.reduce(
            (sum, item) => sum + parseFloat(item.price),
            0
          );
          const totalCount = response?.message?.item.reduce(
            (sum, item) => sum + parseFloat(item.count),
            0
          );
          localStorage.setItem("cartQty", totalCount);
          localStorage.setItem("totalPrice", totalPrice);
          localStorage.setItem("cartClientPath", clientPath);
          navigate(`/${clientPath}/menu`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  return (
    <section>
      {width > breakpoint ? (<>
        <div className='yourorder-fk'>
          <Container>
            <div>

              <h5 className='arrow-tablebooking-text'>
                Order History
              </h5>
            </div>
            <div className='textfield-search'>
              <TextField
                className='search-feild'
                variant="outlined"
                size='small'
                placeholder="Search For Takeawayname...."
                onChange={(e) => setSearchOrder(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className='searchicon' />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="row">
              {currentItems && currentItems.length > 0 ? (
                currentItems.filter((v) =>
                  searchOrder
                    ? v?.takeawayName?.toString()?.toLowerCase()?.includes(searchOrder.toLowerCase())
                    : true
                ).map((OrderDetail, index) => (
                  <div className="col-6" key={index}>
                    <div className="card-order">
                      <div className="order-container">
                        <h6 className="order-header">
                          <img src={OrderDetail.logo} alt="order" className="order-imagefirst" />
                          <span className="restaurant-yourname">{OrderDetail.takeawayName}</span>
                          <span className="waiting-messagebutn">{OrderDetail.orderDeliveryStatus}</span>
                        </h6>
                        <h6 className="order-data">
                          {OrderDetail.orderedDate}{" "}
                          <span className="amount-color">£ {OrderDetail.orderTotalAmount}</span>
                        </h6>
                        <div className="row">
                          <div className="col-6">
                            <Button className="button-viewbill" Oid={OrderDetail?.orderId}
                              Cid={OrderDetail?.clientId}
                              Path={OrderDetail?.clientPath}
                              onClick={navigateToOrderDetail}>
                              View Bill
                            </Button>
                          </div>
                          <div className="col-6">
                            {OrderDetail.orderStatus === "reorder" ? (
                              <Button className="button-reorder" onClick={() => handleOrderAgain(OrderDetail.orderId,
                                OrderDetail.clientId,
                                OrderDetail.clientPath)}>
                                Reorder
                              </Button>
                            ) : (
                              <Button className="button-reorder"
                                Oid={OrderDetail.orderId}
                                Cid={OrderDetail.clientId}
                                onClick={navigateToOrderStatus}>
                                Status
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-favourites">
                  <h5>You Don’t Have Any Order History</h5>
                  <Button onClick={() => navigate('/list')}>Menu</Button>
                </div>
              )}
            </div>

            {/* Pagination Controls */}
            {totalPages > 1 && (
              <div className="paginationorder">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                <span> {currentPage} </span><span>{totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
              </div>
            )}
          </Container>
        </div>
      </>) : (
        <>
          <div className='yourorder-fk'>
            <Container>
              <div>
                <h5 className='arrow-tablebooking-text'>
                  <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} />
                  {/* <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-icon' onClick={handlemyaccountclick} /> */}
                  Order History
                </h5>
              </div>
              <div className='textfield-search'>
                <TextField
                  className='search-feild'
                  variant="outlined"
                  size='small'
                  placeholder="Search For Takeawayname.."
                  onChange={(e) => setSearchOrder(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className='searchicon' />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {orderhistorydatas?.length > 0 ? (
                orderhistorydatas
                  .filter((v) =>
                    searchOrder
                      ? v?.takeawayName?.toString()?.toLowerCase()?.includes(searchOrder.toLowerCase())
                      : true
                  )
                  .map((OrderDetail, index) => (
                    <div key={index} className='card-order'>
                      <div className="order-container">
                        <h6 className="order-header">
                          <img
                            src={OrderDetail.logo}
                            alt="order-image"
                            className='order-imagefirst'
                          />
                          <span className="restaurant-yourname">{OrderDetail.takeawayName}</span>
                          <span className='waiting-messagebutn'>{OrderDetail.orderDeliveryStatus}</span>
                        </h6>
                        <p className='ptag-order'></p>
                      </div>
                      <h6 className='order-data'>
                        {OrderDetail?.orderedDate} <span className='amount-color'>£ {OrderDetail?.orderTotalAmount}</span>
                        <span className='view-menutxt'
                          Oid={OrderDetail?.orderId}
                          Cid={OrderDetail?.clientId}
                          Path={OrderDetail?.clientPath}
                          onClick={navigateToOrderDetail}
                        >View Bill</span>
                      </h6>
                      <div className='buttonorder-divbtn'>
                        {OrderDetail.orderStatus === "reorder" ? (
                          <Button
                            className='button-reorder'
                            onClick={() =>
                              handleOrderAgain(
                                OrderDetail.orderId,
                                OrderDetail.clientId,
                                OrderDetail.clientPath
                              )
                            }
                          >
                            <img src="https://fusionbucket.co.uk/My-account/refresh.image.png" alt="reorder-image" className='reorder-image' />
                            Reorder
                          </Button>
                        ) : (
                          <Button
                            className="button-reorder"
                            Oid={OrderDetail.orderId}
                            Cid={OrderDetail.clientId}
                            onClick={navigateToOrderStatus}
                          >
                            View Status
                          </Button>
                        )}
                      </div>
                    </div>
                  ))
              ) : (
                <div className="no-favourites">
                  <img src={IMAGE_URL} alt="No favourites" />
                  <h5>You Don’t Have Any Order History</h5>
                  <button onClick={() => navigate('/menu')}>Menu</button>
                </div>
              )}

              {totalPages > 1 && (
                <div className="paginationorder">
                  <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                  <span> {currentPage} </span><span>{totalPages}</span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
                </div>
              )}
            </Container>
          </div>
        </>
      )}

    </section>
  );
};

const mapStateToProps = (state) => ({
  orderhistory: state.orderhistory.orderhistory,
  userData: state.userdata.userData,
  orderagain: state.orderagain.orderagain,
});

const mapDispatchToProps = {
  getOrderHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Yourorder);
