import React, { useState, useContext, useEffect } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField } from '@mui/material';
import { connect } from "react-redux";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import './tablebooking.scss';
import "../addressbook/css/addressmodal.scss";
import { LuClock4 } from "react-icons/lu";
import { PiCalendarCheck } from "react-icons/pi";
import { GrGroup } from "react-icons/gr";
import { ImCheckmark } from "react-icons/im";
import { FaRegCircleXmark } from "react-icons/fa6";
import { GoArrowLeft } from "react-icons/go";
import img from "./no_booking_image.png"
import { getTableBooking } from '../../../../actions/myaccount/tablebooking/tableBookingActions';

const Tablebooking = ({ getTableBooking, tableBooking, userData }) => {
    const breakpoint = 992;
    const location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const [current, setCurrent] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [show, setShow] = useState(false);
    const { theme } = useContext(ThemeContext);
    const handleClose = () => setShow(false);
    const tableBookingData = tableBooking?.message || [];
    const [filter, setFilter] = useState('All');
    const [filteredBookings, setFilteredBookings] = useState([]);

    useEffect(() => {
        const filteredData = tableBookingData?.filter(booking => {
            const currentDate = new Date();
            const bookingDate = new Date(booking.table);
            switch (filter) {
                case 'Upcoming':
                    return bookingDate > currentDate && booking.order_status === 'Accepted';
                case 'Past':
                    return bookingDate < currentDate && booking.order_status === 'Accepted';
                case 'Confirmation':
                    return booking.order_status === 'Placed';
                case 'Cancelled':
                    return booking.order_status === 'Rejected';
                default:
                    return true;
            }
        });
        setFilteredBookings(filteredData);
    }, [filter, tableBookingData]);

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 6;
    const totalPages = Math.ceil(filteredBookings.length / recordsPerPage);

    // Get the current page's bookings
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentBookings = filteredBookings.slice(indexOfFirstRecord, indexOfLastRecord);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };


    const handleShow = (modalContent, booking) => {
        setCurrent(modalContent);
        setSelectedBooking(booking);
        setShow(true);
    };
    const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
    const Imagetop = 'https://fusionbucket.co.uk/BlogImages/My-accoount-arrow.png';
    const navigate = useNavigate();

    const handlemyaccountclick = () => {
        setShow(false);
    };
    const handleCancelModel = () => {
        setShow(false);
    };
    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);
    const navigateto = useNavigate();
    if (!location.pathname?.includes("/myaccount") && width > breakpoint) {
        navigateto("/myaccount/tablebooking");
    }
    const getStatusTextt = (status) => {
        switch (status) {
            case 'Accepted':
                return (
                    <Button className='confirmed-btn' style={{ backgroundColor: "#9bff6b", color: "green", border: "1px solid green" }}>
                        <ImCheckmark className="imagefor-text" fill="green" />
                        <span> Accepted</span>
                    </Button>
                );
            case 'review':
                return (
                    <Button style={{ backgroundColor: "#ffcbcb" }} className='imagefor-textrevieww'
                        onClick={() => handleShow("Reviewmodal")} >
                        <img src="https://fusionbucket.co.uk/My-account/reviewicon.png"
                            alt="review-icon" className='imagefor-textreview' />
                        <span> Review</span>
                    </Button>
                );
            case 'Rejected':
                return (
                    <Button className='reject-btn' variant="danger" style={{ backgroundColor: "#ffcbcb", color: "red" }} >
                        <FaRegCircleXmark fill="red" size="25px" className='imagefor-textcancel' />
                        <span> Rejected</span>
                    </Button>
                );
            case 'Placed':
                return (
                    <Button style={{ backgroundColor: "#ffe677", color: "warning" }} className='waiting-text' variant="warning">
                        <img src="https://fusionbucket.co.uk/My-account/fi_66163.png"
                            alt="tick-icon" className='imagefor-text' />
                        <span>Waiting for Confirmation</span>
                    </Button>
                );
            default:
                return null;
        }
    };
    const Modalcontenttable = ({ booking }) => (
        <>
            <div className='tablemodalbooking-op'>
                <h5 className='tablebooking-arrow' style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        Manage My Booking
                    </div>
                    <div><RxCross2 onClick={handlemyaccountclick}
                        style={{
                            margin: "0px 10px",
                            backgroundColor: 'red',
                            borderRadius: "20px",
                            padding: "5px"
                        }}
                    /></div>
                </h5>
                <div className='modalbooking-manage'>
                    <p className='textjaliso'>
                        <span style={{ margin: "16px auto" }}>{booking.restaurant_details.takeaway_name}</span>
                        <p className='text-cardbox'>
                            {getStatusTextt(booking?.order_status)}
                        </p>
                    </p>
                    <div className='modal-footertexttable'>
                        <div className='calender-div' >
                            <PiCalendarCheck
                                style={{ margin: "3px 4px 5px 3px" }}
                                className='model-tableBookingImage' />
                            <span className='model-responseDatetime'>
                                {booking.table}
                            </span>
                        </div>
                        <div className='model-responseTimeDiv'>
                            <LuClock4 className='model-responseTime' />
                            <span style={{ margin: "5px" }}>{booking.time}</span>
                        </div>
                        <div className='model-responseGuestDiv'>
                            <GrGroup className='model-responseGuest' />
                            <span style={{ margin: "0px 0px 0px 4px", }}>
                                {booking?.people}
                            </span>
                        </div>
                        {/* {booking?.discount === 0 &&
                            <div className='model-discount-text' >{booking?.discount}%</div>} */}
                        {booking?.discount !== 0 && (
                            <div
                                className="model-discount-text"
                                style={{ visibility: booking?.discount === 0 ? "hidden" : "visible" }}
                            >
                                {booking?.discount}%
                            </div>
                        )}

                    </div>
                    <p className='button-optionstablebookingmodal'>
                        <Button className='button-callrestaurant'>
                            <a href={`tel:${booking.restaurant_details.business_phone}`} style={{ textDecoration: "none" }}>
                                Call Restaurant
                            </a>
                        </Button>

                        <Button onClick={handleCancelModel} className='button-cancelmodal'>
                            Cancel
                        </Button>
                    </p>
                </div>
            </div>
        </>
    );
    const Reviewmodal = () => (
        <>
            <h4>Leave As A Review </h4>
            <TextField
                multiline
                rows={3}
                placeholder="Type Your Message Here..."
                fullWidth
                className="textfeild-review"
            />
            <Button className='Button-review'> Submit </Button>
        </>
    );
    const CustomTableCard = ({ booking }) => {
        const getStatusText = (status) => {
            switch (status) {
                case 'Accepted':
                    return (
                        <span className='confirmed-text'>
                            <ImCheckmark className="imagefor-text" fill="green" />
                            <span> Accepted</span>
                        </span>
                    );
                case 'review':
                    return (
                        <span className='imagefor-textreview' onClick={() => handleShow("Reviewmodal")}>
                            <img src="https://fusionbucket.co.uk/My-account/reviewicon.png"
                                alt="review-icon" className='imagefor-textreview' />
                            <span> Review</span>
                        </span>
                    );
                case 'Rejected':
                    return (
                        <span className='imagefor-textcancel'>
                            <FaRegCircleXmark fill="red" size="25px" className='imagefor-textcancel' />
                            <span> Rejected</span>
                        </span>
                    );
                case 'Placed':
                    return (
                        <span className='waiting-text'>
                            <img src="https://fusionbucket.co.uk/My-account/fi_66163.png"
                                alt="tick-icon" className='imagefor-text' />
                            <span>Waiting for Confirmation</span>
                        </span>
                    );
                default:
                    return null;
            }
        };

        return (
            <div className='custom-tablecard'  >
                <div className='custom-cardttable'>
                    <img
                        src={booking.logo}
                        alt={"tablebooking"} className='image-tablebooking'
                        onClick={() => handleShow("Modalcontenttable", booking)}
                    />
                    <div className='table-data'>
                        <p className='text-cardbox'>
                            {booking?.restaurant_details.takeaway_name}
                        </p>
                        <p className='text-cardbox'>
                            <span>
                                <img
                                    src="https://fusionbucket.co.uk/My-account/mexican foodicon.png" alt="cuisine-icon" className='imagefor-text' />
                                <span className='cusines'>{booking?.cousines[0]}</span>
                            </span>
                        </p>
                        <p className='text-cardbox'>
                            {getStatusText(booking?.order_status)}
                        </p>
                    </div>
                </div>
                <div className='footer-texttable'>
                    <PiCalendarCheck
                        className='tableBookingImage'
                    />
                    <span className='responseDatetime'>{booking?.table}</span>
                    <div className='responseTimeDiv'>
                        <LuClock4 className='responseTime' />
                        <span style={{ margin: "5px" }}>{booking?.time}</span>
                    </div>
                    <div className='responseGuestDiv'>
                        <GrGroup className='responseGuest' />
                        <span style={{ margin: "0px 0px 0px 4px", }}>{booking?.people}</span>
                    </div>
                    <div className="model-discount-text"
                        style={{ visibility: booking?.discount === 0 ? "hidden" : "visible" }}
                    >
                        {booking?.discount} %
                    </div>
                </div>
            </div>
        );
    };
    useEffect(() => {
        const result = localStorage.getItem("profile");
        const parsedData = JSON.parse(result);
        getTableBooking({
            customer_id: userData?.customerId,
            email: parsedData?.data?.profile?.email
        })
    }, []);
    const [activeFilter, setActiveFilter] = useState('All');

    const handleFilterChange = (filter) => {
        setActiveFilter(filter);
        setFilter(filter);
    };
    return (
        <section>
            {width > breakpoint ? (
                <>
                    <div className='yourorder-fk'>
                        <Container>
                            <div>
                                <h5 className='tablebooking-arrow'>
                                    {/* <MdOutlineArrowBackIos className='arrowtop-tablebk' onClick={() => navigate('/myaccount')} /> */}
                                    Table Booking
                                </h5>
                            </div>
                            <p className='button-optionstablebookingdesktop'>
                                {
                                    tableBooking?.message?.length < 2 ? (
                                        <Button className='button-all' onClick={() => setFilter('All')}>All</Button>
                                    ) : (
                                        <>
                                            <Button
                                                className={`button-all ${activeFilter === 'All' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('All')}
                                            >
                                                All
                                            </Button>
                                            <Button
                                                className={`button-all ${activeFilter === 'Upcoming' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('Upcoming')}
                                            >
                                                Upcoming
                                            </Button>
                                            <Button
                                                className={`button-all ${activeFilter === 'Past' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('Past')}
                                            >
                                                Past Bookings
                                            </Button>
                                            <Button
                                                className={`button-all ${activeFilter === 'Cancelled' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('Cancelled')}
                                            >
                                                Cancelled
                                            </Button>
                                            <Button
                                                className={`button-all ${activeFilter === 'Confirmation' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('Confirmation')}
                                            >
                                                Confirmation
                                            </Button>
                                        </>
                                    )
                                }
                            </p>
                            <div className='row'>
                                {currentBookings &&
                                    Array.isArray(currentBookings) &&
                                    currentBookings?.length > 0 ? (
                                    currentBookings.map(booking => (
                                        <div className='col-6'
                                            key={booking?.id}>
                                            <CustomTableCard
                                                booking={booking}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-favourites">
                                        <img src={img} alt="No favourites" style={{ width: "100%" }} />
                                        <h5>No Booking Available!</h5>
                                        <button onClick={() => navigate('/list')}>Menu</button>
                                    </div>
                                )}
                            </div>
                            {totalPages > 1 && (
                                <div className="pagination">
                                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                                    <span> {currentPage} </span>...<span>{totalPages}</span>
                                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
                                </div>
                            )}
                        </Container>
                        <Modal show={show} onHide={handleClose} dialogClassName="bottom-modaladdress"
                            className={theme === "dark" ? "dark-popup" : null}>
                            <Modal.Body>
                                {current === "Modalcontenttable" && selectedBooking && (
                                    <Modalcontenttable booking={selectedBooking} />
                                )}
                                {current === "Reviewmodal" && <Reviewmodal />}
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
            ) : (
                <>
                    <div className='yourorder-fk'>
                        <Container>
                            <div>
                                <h5 className='tablebooking-arrow'>
                                    <MdOutlineArrowBackIos className='arrowtop-tablebk'
                                        onClick={() => navigate('/myaccount')} />
                                    Table Booking
                                </h5>
                            </div>
                            <p className='button-optionstablebookingdesktop'>
                                {
                                    tableBooking?.message?.length < 2 ? (
                                        <Button className='button-all' onClick={() => setFilter('All')}>All</Button>
                                    ) : (
                                        <>
                                            <Button
                                                className={`button-all ${activeFilter === 'All' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('All')}
                                            >
                                                All
                                            </Button>
                                            <Button
                                                className={`button-all ${activeFilter === 'Upcoming' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('Upcoming')}
                                            >
                                                Upcoming
                                            </Button>
                                            <Button
                                                className={`button-all ${activeFilter === 'Past' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('Past')}
                                            >
                                                Past Bookings
                                            </Button>
                                            <Button
                                                className={`button-all ${activeFilter === 'Cancelled' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('Cancelled')}
                                            >
                                                Cancelled
                                            </Button>
                                            <Button
                                                className={`button-all ${activeFilter === 'Confirmation' ? 'active-button' : ''}`}
                                                onClick={() => handleFilterChange('Confirmation')}
                                            >
                                                Confirmation
                                            </Button>
                                        </>
                                    )
                                }
                            </p>
                            {currentBookings && Array.isArray(currentBookings) && currentBookings.length > 0 ? (
                                currentBookings.map(booking => (
                                    <CustomTableCard key={booking.id} booking={booking} />)
                                )
                            ) : (
                                <div className="no-favourites">
                                    <img src={img} alt="No favourites"  style={{ width: "100%" }}  />
                                    <h5>No Booking Available!</h5>
                                    <button onClick={() => navigate('/list')}>Menu</button>
                                </div>
                            )}
                            {totalPages > 1 && (
                                <div className="pagination">
                                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                                    <span> {currentPage} </span>...<span>{totalPages}</span>
                                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
                                </div>
                            )}
                        </Container>
                        <Modal show={show} onHide={handleClose} dialogClassName="bottom-modaladdress" className={theme === "dark" ? "dark-popup" : null}>
                            <Modal.Body>
                                {current === "Modalcontenttable" && selectedBooking && (
                                    <Modalcontenttable booking={selectedBooking} />
                                )}
                                {current === "Reviewmodal" && <Reviewmodal />}
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
            )}
        </section >

    );
};

const mapStateToProps = (state) => ({
    tableBooking: state.tableBooking.tableBooking,
    userData: state.userdata.userData
});
const mapDispatchToProps = {
    getTableBooking
};
export default connect(mapStateToProps, mapDispatchToProps)(Tablebooking);


